<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="text-center" v-if="id_musyrif == null">
        File ini di input pada Portal Akademik, Anda tidak memiliki Akses ke
        File terkait
      </q-card-section>
      <q-card-section v-else>
        <q-img :src="src" spinner-color="white" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="close"
          color="negative"
          @click="onCancelClick"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["nama_file", "id_musyrif", "asal"],
  data() {
    return {
      src: null
    };
  },
  mounted() {
    if (this.asal == "laporan") {
      this.src = `../laporan_kerusakan/${this.nama_file}`;
    } else {
      this.src = `../bukti_pengasuhan/${this.nama_file}`;
    }
  },
  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {};
      this.$emit("ok", data);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
