<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          outlined
          v-if="!frominput && user.is_super_user == '1'"
          v-model="selMusyrif"
          label="Musyrif"
          :options="musyrif"
          @input="
            selSiswa = null;
            getSiswaList();
          "
        />
        <q-select
          :readonly="frominput"
          use-input
          @filter="filterFnSiswa"
          outlined
          v-model="selSiswa"
          label="Siswa"
          :options="filteredListSiswa"
          @input="getPembinaanDetail()"
        />
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section class="q-gutter-sm" v-if="selSiswa != null">
        <q-input
          readonly
          dense
          outlined
          :value="selSiswa.asrama"
          label="Asrama"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="selSiswa.kamar"
          label="Kamar"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="selSiswa.ortu"
          label="Orang Tua"
        ></q-input>
        <q-input
          readonly
          dense
          outlined
          :value="selSiswa.kontak"
          label="Kontak"
        ></q-input>
      </q-card-section>
      <q-tabs
        v-if="frominput"
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        indicator-color="orange"
      >
        <q-route-tab
          v-if="selSiswa != null"
          :to="'/penilaianinputpembinaan/' + selSiswa.value"
          name="input"
          label="INPUT"
        />
        <q-tab name="riwayat" label="RIWAYAT" />
      </q-tabs>
      <q-scroll-area style="height:calc(100vh - 338px);">
        <q-list bordered separator class="bg-indigo-1">
          <q-item v-if="selSiswa == ''">
            <p class="text-center text-overline" style="width:100%">
              Silahkan Pilih Siswa Dahulu
            </p>
          </q-item>
          <q-item v-else-if="tanggal.length == 0">
            <p class="text-center text-overline" style="width:100%">
              Belum Ada Riwayat Pembinaan
            </p>
          </q-item>
          <q-item class="column" v-for="(val, i) in tanggal" :key="i">
            <p class="row justify-between q-mb-sm">
              <strong>Tanggal</strong>
              <a>{{ val.tanggal }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Kasus:</strong>
              <a>{{ val.kasus }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Penyelesaian:</strong>
              <a>{{ val.penyelesaian }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Bukti:</strong>
              <a v-if="val.nama_file == '' || val.nama_file == null">-</a>
              <q-btn
                v-else
                flat
                dense
                @click="showDialog(val)"
                color="primary"
                label="show"
                size="sm"
              ></q-btn>
            </p>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-card>
  </div>
</template>

<script>
import DialogShowGambar from "@/components/DialogShowGambar";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: "riwayat",

      user: {},
      musyrif: [],
      selMusyrif: {
        value: null,
      },

      selSiswa: null,
      siswa: [],
      filteredListSiswa: [],
      tanggal: [],
      searchTerm: "",
    };
  },
  computed: {
    frominput() {
      if (this.$route.params.id < 0) return false;
      else return true;
    },
  },
  async mounted() {
    if (this.$route.params.id < 0) {
      await this.getUser();
      if (this.user.is_super_user == "1") {
        await this.getMusyrif();
      } else {
        this.selMusyrif.value = this.user.id;
      }
      await this.getSiswaList();
    } else {
      await this.getSiswaDetail();
      await this.getPembinaanDetail();
    }
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    showDialog(val) {
      this.$q
        .dialog({
          component: DialogShowGambar,
          parent: this,
          nama_file: val.nama_file,
          id_musyrif: val.id_musyrif,
          asal: "pembinaan",
        })
        .onOk((resp) => {});
    },
    filterFnSiswa(val, cb) {
      if (val == "") {
        cb(() => {
          this.filteredListSiswa = JSON.parse(JSON.stringify(this.siswa));
        });
        return;
      }

      cb(() => {
        const needle = val.toLowerCase();
        this.filteredListSiswa = this.siswa.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(`/hasil/rekap_pembinaan/getlist/${this.selMusyrif.value}`)
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/hasil/rekap_pembinaan/getdetail/" + this.$route.params.id)
        .then((resp) => {
          this.selSiswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getPembinaanDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.tanggal = [];
      if (this.selSiswa != null) {
        await this.$http
          .get("/hasil/rekap_pembinaan/getpembinaan/" + this.selSiswa.value)
          .then((resp) => {
            this.tanggal = resp.data;
          });
      }
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
